import './App.css';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { theme } from './styles/muiStyles';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './redux/store';
import CartMenu from './components/cartMenu';
import AnimatedRoutes from './components/animatedRoutes';
import InactiveSignout from './components/inactiveSignOut';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router>
            <AnimatedRoutes />
            <CartMenu />
            <InactiveSignout />
          </Router>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;

/*




*/
