import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify/utils';
import { signOut } from 'aws-amplify/auth';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/sliceLoggedIn';
import { setCheckoutState } from '../redux/sliceCheckout';

function InactiveSignout() {
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = Hub.listen('auth', async (data) => {
      const { payload } = data;

      if (payload.event === 'tokenRefresh_failure') {
        await signOut({ global: true });
        dispatch(logout());
        dispatch(setCheckoutState({ stripeCustomerID: null }));
        setShowLogoutMessage(true);
      }
    });

    return () => {
      listener();
    };
  }, [dispatch]);

  const handleAlertClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowLogoutMessage(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <>
      <Snackbar
        open={showLogoutMessage}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          severity="warning"
          sx={{ width: '100%' }}
          onClose={handleAlertClose}
          role="alert"
        >
          You have been logged out due to inactivity.
        </Alert>
      </Snackbar>
    </>
  );
}

export default InactiveSignout;
